/* App.css */
.App {
  font-family: Arial, sans-serif;
}

header {
  background-color: #ccc;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}

nav {
  background-color: #ccc;

  text-align: center;
  list-style: none; /* Remove bullets from the menu */
}

nav a {
  color: #333;
  text-decoration: none;
  padding: 10px 20px;
  display: inline-block; /* Ensure each link takes full width */
  text-align: center; /* Center the text */
}

nav a.activeLink {
  background-color: #666;
}
.sound-wave {
  width: 100%;
  margin-bottom: 10px !important;
}

main {
}

.ant-btn-primary:hover {
  background: #5a5a5a;
}

.ant-collapse-header-text {
  color: #ffffff;
}
.ant-collapse-header {
  background-color: #5a5a5a;
}
.ant-collapse-content-box {
  padding: 0px !important;
}
